<template>
  <div v-if="stock !== null" class="cmp-product-stock utlz-reset">
    <!-- BESPOKE BAAK: Disable stock if product is out of stock -->
    <template v-if="stock.stockTotal > 0">
      <div class="stock-wrapper">
        <template v-if="stock !== null">
          <template v-if="stockType === 1">
            <span class="stock-label" v-translation="{type: 'label', code: 'label_stock_indicator'}">:</span>
            <span class="stock-count">{{ stockTotal }}</span>
          </template>
          <template v-else-if="stockType === 4">
            <div class="stock-indicators">
              <img :src="'/' + stockIndicator.image" />
              <span>{{ stockIndicator.description }}</span>
            </div>
          </template>
        </template>
        <div v-else-if="showStock" class="stock-status-wrapper text-placeholder pulse">
          <span class="loader"></span>
          <span v-translation="{ type: 'label', code: 'label_retrieving_stock' }"></span>
        </div>
      </div>
    </template>
    <!-- END BESPOKE BAAK -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    stock: { type: Object, required: false }
  },
  computed: {
    ...mapGetters(['showStock', 'stockIndicators', 'stockType']),
    stockTotal () {
      return this.stock.stockTotal < 0 ? 0 : this.stock.stockTotal;
    },
    stockIndicator () {
      let indicatorIndex = 0;
      this.stockIndicators.forEach((indicator, index) => {
        if (indicator.quantity <= this.stock.stockTotal) {
          indicatorIndex = index; 
        }
      });
      return this.stockIndicators[indicatorIndex];
      return this.stockIndicators.find(indicator => this.stock.stockTotal >= indicator.quantity);
    }
  }
}
</script>

<style>
</style>
